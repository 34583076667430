import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

import Flex from 'components/ui/Flex';
import Typography from 'components/ui/Typography';
import { breakPoint, palette } from 'shared/styles';

const members = [
  {
    nickname: 'Dave',
    name: '김수영',
    job: 'Client Programmer',
    comment: '어제보다 나은 오늘을 살자',
  },
  {
    nickname: 'Judy',
    name: '장연정',
    job: 'UI/UX designer',
    comment: '매 순간을 소중하게 생각하자',
  },
  {
    nickname: 'Kite',
    name: '이서연',
    job: 'Server Programmer',
    comment: '매일 당신을 두렵게 만드는\n코드를 하나씩 쳐라',
  },
  {
    nickname: 'Eddy',
    name: '신원철',
    job: 'AI Researcher',
    comment: 'AI가 단조로운 일로부터\n인간을 해방시키는 그날까지',
  },
  {
    nickname: 'Jacey',
    name: '박은정',
    job: 'Product Manager',
    comment: '사람을 소중하게 생각합니다.',
  },
  {
    nickname: 'Hina',
    name: '정가희',
    job: 'Product Manager',
    comment: '효율적으로 일하자',
  },
  {
    nickname: 'Sally',
    name: '백지선',
    job: 'Client Programmer',
    comment: '항상 즐겁게, 후회없이',
  },
  {
    nickname: 'Woody',
    name: '박건우',
    job: 'Server Programmer',
    comment: '커뮤니케이션을 통해 문제를\n해결해나가는 개발자',
  },
  {
    nickname: 'June',
    name: '김준혁',
    job: 'RA Specialist',
    comment: '숲을 보고 나무 가꾸기',
  },
  {
    nickname: 'Henry',
    name: '이석환',
    job: 'RA Specialist',
    comment: '디테일하고 계획적인것을 선호합니다.',
  },
  {
    nickname: 'Semi',
    name: '정세미',
    job: 'RA Specialist',
    comment: '저는 활동적인 것을 좋아하는 내향인입니다.',
  },
  {
    nickname: 'Skylar',
    name: '전소현',
    job: 'RA Specialist',
    comment:
      '에너지가 넘치고 친절하여, 주변을\n밝고 따뜻하게 만드는 사람입니다.',
  },
  {
    nickname: 'Narf',
    name: '김건중',
    job: 'QCQA Specialist',
    comment: '늘 배움의 자세로 진지해지는\n공대출신 아저씨',
  },
  {
    nickname: 'Rachel',
    name: '이예은',
    job: 'QCQA Specialist',
    comment:
      '밝은 미소와 긍정적인 태도로 모든 사람\n과 쉽게 어울리는 사교적인 사람입니다.',
  },
  {
    nickname: 'Aurora',
    name: '유진선',
    job: 'QCQA Specialist',
    comment: '소소한 계획을 세우고 성취해\n나가는 것을 좋아합니다.',
  },
  {
    nickname: 'Lily',
    name: '고진희',
    job: 'RA Specialist',
    comment: '시작과 끝이 되는 사람,\n시작과 끝이 되는 일',
  },
  {
    nickname: 'Kay',
    name: '김종화',
    job: 'Consultant',
    comment: '편안한 사람입니다.',
  },
  {
    nickname: 'Owen',
    name: '이정우',
    job: 'Consultant',
    comment: '상대방과 화합을 중요시 하는 사람',
  },
  {
    nickname: 'Amy',
    name: '신지영',
    job: 'Consultant',
    comment: '안녕하세요! 걸어다니는 비타민\n신지영 입니다!',
  },
  {
    nickname: 'Gloria',
    name: '양연정',
    job: 'Clinical Trial Consultant',
    comment: '모든 일에 긍정적으로 생각하고\n행동합니다.',
  },
  {
    nickname: 'Blake',
    name: '정성도',
    job: 'Consultant',
    comment: '진취적인 사람입니다',
  },
  {
    nickname: 'Matthew',
    name: '안형준',
    job: 'Consultant',
    comment: '매사에 감사하며 태도에 일관성을\n가지고 있는 사람',
  },
  {
    nickname: 'Melody',
    name: '김슬기',
    job: 'Clinical Trial Consultant',
    comment: '긍정적인 사람입니다.',
  },
  {
    nickname: 'Layla',
    name: '김윤진',
    job: 'Sales Enablement',
    comment: '안과 밖이 똑같은 사람',
  },
  {
    nickname: 'Leo',
    name: '김용주',
    job: 'Consultant',
    comment: '‘불가능은 없다’ 를 지향하는\n사람입니다.',
  },

  {
    nickname: 'Elin',
    name: '정혜리',
    job: 'RA enablement',
    comment: '밝고,책임감 있는 사람입니다!',
  },
  {
    nickname: 'Philip',
    name: '김현규',
    job: 'Account',
    comment: '슬기로운 회사생활!!',
  },
  {
    nickname: 'Edward',
    name: '박준영',
    job: 'Account',
    comment: '경험해보기 전까지 편견을\n갖지 않는 사람입니다.',
  },
  {
    nickname: 'Sophie',
    name: '김송아',
    job: 'Account',
    comment: '항상 최선을 다하는\n낙천주의자 입니다:) ',
  },
];

const SectionA = () => {
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTablet = useMediaQuery({ query: breakPoint.TABLET });

  const leaders = [
    {
      nickname: 'Harry',
      name: '김준일 (Harry) / CEO',
      title: `“뷰티와 IT의 결합을 통해${
        isTablet ? ' ' : '\n'
      }새로운 패러다임을 만들어 보겠습니다.”`,
      description:
        '팜스킨 공동창업을 하며 영업과 SCM을 맡아 화장품 업계를 경험하였습니다.\n이러한 경험을 통해 화장품 시장의 편리성을 증대시키고 자유로운 소통을 이루고 싶어 CDRI를 창업했습니다.\n폭발적인 열정과 추진력, 배려심과 자상함을 겸비하여 경영자로서 훌륭한 팀원을 모시고, 성공하는 조직 문화를 만들기 위해 노력하고 있습니다.',
      ps: '저는 활동적인 것을 좋아합니다.\n축구, 등산, 자전거 타기등 계속해서 체력을 키우고 있으며 이러한 체력을 밑바탕으로 하여 더 많은 활동들을 하고자 합니다.',
    },
    {
      nickname: 'Joy',
      name: '엄정식 (Joy) / CHRO',
      title: `“해야 할 일이 무엇인지\n알고 있고 할 수 있습니다.”`,
      description:
        '화장품 브랜드사에서 15년 이상을 근무하며,산업과 인재에 대한 통찰력을 갖고 있습니다.인사, 총무, 법무, M&A에 이르는 관리 경험과 역량은 안정적이고 예측 가능하며, 기업 가치의 제고를 위한 경영을 지원할 것입니다.',
      ps: '“왜” 해야 하는지 그리고 “어떻게” 해결할 것인지에 대해 고민하고 답을 제시하겠습니다.',
    },
  ];

  return (
    <Container>
      <Content isMobile={isMobile} isTablet={isTablet}>
        <Typography.Title
          type={'h1'}
          css={css`
            ${isTablet &&
            css`
              font-size: 36px;
              line-height: 46px;
            `}
            ${isMobile &&
            css`
              font-size: 24px;
              line-height: 34px;
            `}
          `}
        >
          TEAM
        </Typography.Title>
        <Typography.Title
          type="b1"
          color="DARK_GREY"
          css={css`
            margin-top: 36px;
            ${isMobile &&
            css`
              margin-top: 16px;
              font-size: 14px;
              line-height: 22px;
            `};
          `}
        >
          원료사, 제조사, 브랜드사, 유통사 등 화장품 산업 전문가와
          데이터사이언스, 컨설팅, 디자이너 등 산업 내 전문가들이 모인 팀<br />
          폭넓은 경험과 지식을 가진 팀이 고객의 문제를 해결하고 불편함을
          개선하고 마음을 얻을 것입니다.
        </Typography.Title>
        <Flex
          $wrap={isMD}
          gap={40}
          direction={isMD ? 'column' : 'row'}
          css={css`
            margin-top: 120px;

            ${isMD &&
            css`
              margin-top: 56px;
            `}
          `}
        >
          {leaders.map((leader) => (
            <Leader key={leader.nickname} {...leader} />
          ))}
        </Flex>
        <Flex
          $wrap
          direction={isMobile ? 'column' : 'row'}
          css={css`
            margin: 56px 0;
            row-gap: 8px;
            column-gap: 16px;
          `}
        >
          {members.map((member) => (
            <Member key={member.nickname} {...member} />
          ))}
        </Flex>
      </Content>
    </Container>
  );
};

export default SectionA;

const Container = styled.section`
  background-color: ${palette.LIGHT_GREY1};
  border-bottom: 1px solid ${palette.GREY1};
`;

const Content = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  max-width: 1248px;
  margin: 0 auto;
  padding: 252px 24px 0;

  ${({ isTablet }) =>
    isTablet &&
    css`
      padding-top: 180px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-top: 116px;
    `}
`;

const Leader = ({
  nickname,
  name,
  title,
  description,
  ps,
}: {
  nickname: string;
  name: string;
  title: string;
  description: string;
  ps: string;
}) => {
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isTablet = useMediaQuery({ query: breakPoint.TABLET });

  return (
    <LeaderContainer isMobile={isMobile} isMD={isMD}>
      <ProfileImage
        src={`https://cdri.s3.ap-northeast-2.amazonaws.com/assets/profile/${nickname}.png`}
        alt="profile"
        isMobile={isMobile}
      />
      <Typography.Title
        type="h3"
        align="center"
        css={css`
          margin-top: 32px;

          ${isMobile &&
          css`
            font-size: 18px;
            line-height: 28px;
          `}
        `}
      >
        {name}
      </Typography.Title>
      <Typography.Title
        type="h3"
        align="center"
        color="DARK_GREY"
        weight={700}
        css={css`
          margin-top: 32px;
          white-space: pre-line;
          word-break: keep-all;

          ${isMobile &&
          css`
            margin-top: 24px;
            font-size: 18px;
            line-height: 28px;
          `}
        `}
      >
        {title}
      </Typography.Title>
      <Typography.Title
        type="b2"
        color="DARK_GREY"
        css={css`
          margin-top: 24px;
          white-space: pre-line;

          ${isMobile &&
          css`
            margin-top: 24px;
            font-size: 14px;
            line-height: 22px;
          `}
        `}
      >
        {description}
      </Typography.Title>
      <Divider isMobile={isMobile} />
      <Typography.Title
        type="b2"
        color="DARK_GREY"
        css={css`
          margin-top: 36px;
          white-space: pre-line;

          ${isTablet &&
          css`
            margin-top: 24px;
          `}

          ${isMobile &&
          css`
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;
          `}
        `}
      >
        {ps}
      </Typography.Title>
    </LeaderContainer>
  );
};

const LeaderContainer = styled.div<{
  isMobile: boolean;
  isMD: boolean;
}>`
  flex: 1 1 33%;
  padding: 40px 56px;
  background-color: #f1f5f8;
  border-radius: 12px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 32px 16px;
    `}

  ${({ isMD }) =>
    isMD &&
    css`
      :nth-of-type(n + 2) {
        margin-top: 24px;
      }
    `}
`;

const ProfileImage = styled.img<{ isMobile: boolean }>`
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 160px;
      height: 160px;
    `}
`;

const Divider = styled.div<{ isMobile: boolean }>`
  width: 16px;
  height: 2px;
  margin: 24px 0;
  background-color: ${palette.GREY1};

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 16px 0;
    `}
`;

const Member = ({
  nickname,
  name,
  job,
  comment,
}: {
  nickname: string;
  name: string;
  job: string;
  comment: string;
}) => {
  const isMobile = useMediaQuery({ query: breakPoint.MOBILE });
  const isMD = useMediaQuery({ query: breakPoint.MEDIUM_DEVICE });

  return (
    <MemberContainer isMD={isMD} isMobile={isMobile}>
      <ProfileImage
        src={`https://cdri.s3.ap-northeast-2.amazonaws.com/assets/profile/${nickname}.png`}
        alt="profile"
        isMobile={isMobile}
      />
      <Typography.Title
        type="h3"
        align="center"
        css={css`
          margin-top: 24px;

          ${isMobile &&
          css`
            font-size: 18px;
            line-height: 28px;
          `}
        `}
      >
        {name} ({nickname})
      </Typography.Title>
      <Typography.Title
        type="b2"
        align="center"
        color="DARK_GREY"
        css={css`
          white-space: pre-line;

          ${isMobile &&
          css`
            font-size: 14px;
            line-height: 22px;
          `}
        `}
      >
        {job}
      </Typography.Title>
      <Typography.Title
        type="b3"
        align="center"
        color="DARK_GREY"
        css={css`
          margin-top: 8px;
          white-space: pre-line;

          ${isMobile &&
          css`
            font-size: 14px;
            line-height: 22px;
          `}
        `}
      >
        {comment}
      </Typography.Title>
    </MemberContainer>
  );
};

const MemberContainer = styled.div<{ isMD: boolean; isMobile: boolean }>`
  flex: 0 0 calc(25% - 12px);
  padding: 32px 0;

  ${({ isMD }) =>
    isMD &&
    css`
      flex: 0 0 calc(50% - 8px);
    `}

  ${({ isMobile }) => isMobile && css``}
`;
